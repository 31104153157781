const colors = {
  pomegranate: '#f23429',
  mariner: '#257ccf',
  bluebell: '#9593c9',
  sycamore: '#7e8a44',
  tacao: '#efae77',
  sorbus: '#fb7a0c',
  athens: '#ebecef',
  black: '#000000'
}

export { colors };
